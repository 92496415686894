@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/PoppinsRegular.eot');
  src: url('assets/fonts/PoppinsRegular.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/PoppinsRegular.woff2') format('woff2'),
  url('assets/fonts/PoppinsRegular.woff') format('woff'),
  url('assets/fonts/PoppinsRegular.ttf') format('truetype'),
  url('assets/fonts/PoppinsRegular.svg#PoppinsRegular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/PoppinsSemiBold.eot');
  src: url('assets/fonts/PoppinsSemiBold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/PoppinsSemiBold.woff2') format('woff2'),
  url('assets/fonts/PoppinsSemiBold.woff') format('woff'),
  url('assets/fonts/PoppinsSemiBold.ttf') format('truetype'),
  url('assets/fonts/PoppinsSemiBold.svg#PoppinsSemiBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Infinigras';
  src: url('assets/fonts/Infinigras.eot');
  src: url('assets/fonts/Infinigras.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Infinigras.woff2') format('woff2'),
  url('assets/fonts/Infinigras.woff') format('woff'),
  url('assets/fonts/Infinigras.ttf') format('truetype'),
  url('assets/fonts/Infinigras.svg#PoppinsSemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Infinigras';
  src: url('assets/fonts/Infiniromainwebfont.eot');
  src: url('assets/fonts/Infiniromainwebfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Infiniromainwebfont.woff2') format('woff2'),
  url('assets/fonts/Infiniromainwebfont.woff') format('woff'),
  url('assets/fonts/Infiniromainwebfont.ttf') format('truetype'),
  url('assets/fonts/Infiniromainwebfont.svg#PoppinsSemiBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

$font: 'Poppins', arial, sans-serif;
$title: 'Infinigras', arial, sans-serif;

$color_1: #337ab7;
$color_2: #d2421f;
$color_3: #333333;
$color_4: #ff0000;
$color_5: #2e2d2b;
$color_6: #0071be;
$color_7: #97341b;
$color_8: #3d9edf;
