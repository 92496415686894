body {
  margin: 0;
  font-family: $font;
  font-size: 18px;
  line-height: 26px;
  font-weight: normal;
  background-color: #f6f5f3;
}

.wrap {
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: auto;
}

.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
  font-family: $title;
  font-weight: bold;
  line-height: 1.1;
  color: inherit;
}

.h1, h1 {
  font-size: 36px;
}

h2, .h2 {
  line-height: 60px;
  font-size: 53px;
}

h3, .h3 {
  font-size: 30px;
  color: inherit;
  font-weight: bold;
}

b, strong {
  font-weight: 700;
}

.btn {
  display: inline-block;
  width: 229px;
  background-color: $color_4;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  padding: 15px;
  padding-left: 30px;
  margin: 0;
  margin-top: 20px;
  text-decoration: none;
  position: relative;

  &:hover {
    background: $color_7;
  }

  i {
    position: absolute;
    right: 15px;
  }
}

.post-content {
  color: $color_3;

  .wp-block-image {
    margin-top: 40px;

    img {
      width: auto;
      height: auto;
    }
  }

  .contact-table {
    max-width: 844px;

    tr td:first-child {
      min-width: 350px;
    }
  }

  .wp-block-table {
    margin: 0;

    td {
      vertical-align: top;
    }

    tbody {
      border-top: 2px solid #ddd;
    }

    tr:first-child {
      border-bottom: solid 2px #d1cdc6;
    }

    td {
      border-bottom: solid 1px #d1cdc6;
      padding: 5px 20px 5px 5px;
      color: #444444;
      border-top: none;
    }

    p, a, li, span, td {
      color: inherit;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 10px;
      font-weight: normal;
    }
  }

  ul:not([class]) {
    list-style: none;
    padding-left: 0;

    > li {
      margin-bottom: 15px;
      margin-left: 27px;
      font-size: 16px;
      line-height: 23px;
      color: inherit;
    }

    > li:last-child {
      padding-bottom: 0;
    }

    > li::before {
      content: "";
      margin-left: -27px;
      margin-right: 7px;
      display: inline-block;
      vertical-align: top;
      width: 19px;
      height: 19px;
      background: url(assets/images/bg_main_menu_li_x2.png);
      background-size: cover;
    }

  }

  ol:not([class]) {
    padding: 0;
    list-style: none;
    position: relative;
    margin-top: 15px;
    margin-bottom: 30px;
    counter-reset: li;

    > li {
      counter-increment: li;
      font-size: 16px;
      line-height: 23px;
      color: inherit;
      margin-bottom: 30px;
      margin-left: 30px;

      &::before {
        content: counter(li) ". ";
        color: $color_6;
        font-family: $title;
        font-weight: normal;
        font-size: 26px;
        display: inline-block;
        position: absolute;
        margin-left: -30px;
        transform: translate(0px, -4px);
      }

      ul {
        padding-top: 15px;
      }
    }

    > li:last-child {
      padding-bottom: 0;
    }

  }

  h1, .h1 {
    font-size: 30px;
    line-height: 40px;
    color: $color_6;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: normal;
  }

  h2, .h2 {
    color: $color_6;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: normal;
  }

  h3, .h3 {
    color: $color_6;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: normal;
  }

  h4, .h4 {
    color: $color_6;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: normal;
  }

  h5, .h5 {
    color: $color_6;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;
    font-weight: normal;
  }

  p, span, li {
    color: inherit;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 10px;
    font-weight: normal;
  }

  a {
    color: $color_6;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 10px;
    font-weight: normal;
    text-decoration: none;

    &:hover {
      color: $color_2;
    }
  }
}

.row {
  display: flex;
  width: 100%;
}

.column-1 {
  width: 100%;
  padding-right: 15px;
}

.column-2 {
  max-width: 300px;
  width: 100%;
}

.column-full {
  width: 100%;
}

.back-to-list {
  margin-bottom: 30px;

  a {
    font-size: 18px;
  }
}

.pagination {
  text-align: center;
  margin: 30px auto;

  .page-numbers {
    position: relative;
    padding: 0;
    margin: 0 2px;
    display: inline-block;
    text-decoration: none;
    color: $color_6;
    font-size: 18px;
    line-height: 26px;
  }

  .current {
    color: $color_8;
  }

  .prev {
    padding: 0;
    margin: 0 10px 0 0;
  }

  .next {
    padding: 0;
    margin: 0 0 0 10px;
  }
}

.single-inner-gallery {

  .page-header {

    .wrap {
      margin: auto !important;
    }

    h1 {
      font-size: 60px !important;
      font-weight: 700 !important;
      padding: 0 !important;
    }

  }
}

.archive-event .post-content p {
  margin: 0;
}

.blog-archive-template {

  .column-1 {
    max-width: 360px;
    padding-right: 30px;
  }

  .column-2 {
    max-width: unset;
  }

  .row {
    margin-top: 30px;
  }

  .archive-filters-list {
    margin-bottom: 30px;
    margin-top: 5px;

    span {
      background: #fff;
      padding: 3px 15px;
      border: 1px solid #b7b7b7;
      border-radius: 24px;
      display: inline-block;
      margin-bottom: 10px;
      color: #0079af;
      font-family: $title;
      font-weight: normal;
      font-size: 16px;
      cursor: pointer;

      &::after {
        content: "x";
        margin-left: 10px;
        color: #000;
        font-size: 20px;
        font-family: monospace;
      }
    }

    h4 {
      font-size: 16px;
      margin: 0 0 10px 0;
      font-weight: normal;
    }
  }
}

.archive-filter {
  background: #fff;
  padding: 20px;
  margin-bottom: 60px;
  border: 1px solid #b7b7b7;

  .filter-wrap {
    padding-bottom: 30px;
  }

  h4 {
    font-size: 16px;
    margin: 0 0 10px 0;
    font-weight: normal;
  }

  label {
    display: block;
    font-size: 16px;
    font-family: $title;
    font-weight: bold;
    color: #888888;
    cursor: pointer;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    margin-right: 10px;
    margin-left: 0;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    width: 19px;
    height: 13px;
    outline: none;
    position: relative;
    transform: translate(0px, 3px);

    &:before {
      content: "";
      height: 19px;
      width: 19px;
      background: #ffffff;
      border: 1px solid #b7b7b7;
      display: inline-block;
      position: relative;
      z-index: 1;
      border-radius: 4px;
    }

    &:after {
      content: "";
    }

    &:checked:after {
      content: "";
      height: 7px;
      width: 5px;
      display: block;
      transform: rotate(45deg) translate(-9px, -18px);
      border-bottom: 3px solid #000000;
      border-right: 3px solid #000000;
      position: absolute;
      z-index: 100;
    }
  }
}

.archive-post-list {

  .single {
    background: #ffffff;
    display: flex;
    margin-bottom: 30px;
    position: relative;

    .image {
      width: 200px;
      height: 150px;
      overflow: hidden;
      margin-right: 40px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .metadata {
      display: flex;
      flex-wrap: wrap;
      margin-right: -50px;
    }

    .meta {
      display: block;
      font-size: 18px;
      font-family: $title;
      font-weight: bold;
      color: #888888;
      margin-right: 20px;
    }

    .author {
      display: block;
      font-size: 18px;
      font-family: $title;
      font-weight: bold;
      color: #000000;
      margin-right: 20px;
    }

    .event {
      display: block;
      font-size: 18px;
      font-family: "Infinigras",arial,sans-serif;
      font-weight: bold;
      color: #0079af;
      margin-right: 20px;
    }

    .h2, h2 {
      font-size: 26px;
      line-height: 30px;
      margin: 15px 0 0 0;
      text-decoration: none;
      color: #0079af;
      font-weight: normal;
      display: block;
    }

    .content {
      padding: 20px 0;
      width: 480px;
    }

    .icon {
      display: block;
      margin: auto;

      img {
        margin: auto;
        display: block;
      }
    }

    .link {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

ul.archive-gallery {
  padding: 0;
  list-style: none;
  margin: 0 -13px;
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 13px;
    width: 204px;
    height: 120px;

    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}

.archive-page {

  .single-post {
    max-width: unset;
    display: flex;
    clear: both;
    background: url(assets/images/bg_news_spacer_x2.png) no-repeat bottom center;
    background-size: auto 23px;
    margin-bottom: 25px;
    padding-bottom: 25px;

    .image {
      width: 157px;
      height: 127px;
      padding-bottom: 5px;
      min-width: 157px;
      margin-right: 20px;
    }

    .content {
      padding: 0;
      height: unset;
      background: transparent;
      font-size: 16px;
      line-height: 23px;
      max-width: 772px;
      width: 100%;

      .excerpt {
        padding-bottom: 10px;
      }

      .h2 {
        font-size: 34px;
        line-height: 40px;
        margin: 0;
      }
    }

    .meta {
      background: transparent;
      color: #333333;
      margin: 0;
      margin-left: auto;
      width: 160px;
      padding: 0;

      span {
        font-size: 16px;
        line-height: 16px;
        font-family: $title;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        display: block;
        font-weight: 500;
      }

      span:last-child {
        font-family: $font;
        font-size: 90px;
        line-height: 90px;
        letter-spacing: normal;
      }
    }
  }

}

.single-inner-page {

  .page-header {

    .back-to-list {

      i.btn-arrow {
        width: 15px;
        float: left;
        height: 48px;
        margin-top: -12px;
        background: url(assets/images/btn_arrow.png) center no-repeat;
        background-size: auto 30px;
        transform: scaleX(-1);
      }

      a {
        color: #ffffff;
        padding-left: 24px;
        position: relative;
        text-decoration: none;

        &:hover {
          color: #eae8e4;
        }
      }
    }

    .wrap {
      margin-top: 0;
    }

    h1 {
      font-size: 35px;
      line-height: 40px;
      font-family: $title;
      color: #f5f5f5;
      text-transform: none;
      font-weight: normal;
      padding-top: 45px;
    }

  }

  .featured-image {
    margin-bottom: 10px;
    margin-top: -25px;
    position: relative;
    display: table;
    min-height: 25px;

    img {
      max-width: 500px;
      min-width: 400px;
      height: auto;
    }
  }

  .meta {
    margin-top: -8px;
    position: relative;
    background: url(assets/images/bg_menu.png);
    width: 100%;
    height: auto;
    display: flex;

    .date {
      width: 35%;
      min-height: 25px;
      padding: 6px 0;
      color: #ffffff;
      font-size: 16px;
      background: #444444;
      text-align: center;
      display: block;
      margin: 0;
      position: relative;

      span {
        position: absolute;
        left: 50%;
        top: 50%;
        text-align: center;
        margin: 0;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }

    .btn {
      width: 70%;
    }
  }

}

.calendar {
  max-width: 404px !important;
}

body .ui-datepicker-calendar .infobox {
  background: white;
  position: absolute;
  bottom: 40px;
  width: 170px;
  transform: translate(-130px, 0px);
  display: inline-block;
  z-index: 100;
  padding: 20px;
  border: 1px solid #DEDEDE;
  text-align: left;
  box-shadow: 0 10px 40px rgba(0, 0, 0, .2);

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #ffffff;
    left: 40%;
    right: 0;
    transform: translate(0px, 15px);
    position: absolute;
    bottom: 0;
  }

  span {
    font-size: 12px;
    display: block;
    text-align: left;
    line-height: normal;
    height: unset;
    width: unset;
  }

  a {
    font-style: normal;
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #373122 !important;
    text-align: left;
    max-width: unset;
    display: block;
    margin-bottom: 10px;
    height: auto !important;
    width: auto !important;

    &:hover {
      color: #E94D1D !important;
    }
  }

  a:last-child {
    margin-bottom: 0;
  }
}

.date-lesson-day a {
  color: #0079af !important;
}

.page-header {
  position: relative;
  margin: auto;
  background-size: cover !important;
  height: 330px !important;
  display: flex;
  color: #FFFFFF;

  h1 {
    line-height: 85px;
    font-size: 60px;
    color: inherit;
    padding: 0;
    margin: 0;
    display: block;
    width: 100%;
  }
}

.breadcrumbs {
  padding: 0;
  margin-bottom: 5px;
  margin-top: 30px;
  position: relative;

  li {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    padding: 0;
    margin: 0;

    a {
      font-size: 16px;
      line-height: 23px;
      font-family: $font;
      font-weight: normal;
      color: $color_6;
      text-decoration: none;
    }

    span {
      font-family: $font;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;
      padding: 0 5px;
      color: #ccc;
    }

    &:last-child {

      a {
        color: #777777;
        user-select: none;
        pointer-events: none;
      }

      span {
        display: none;
      }

    }
  }
}

.page-sidebar {
  background: transparent;
  padding-left: 10px;

  h4 {
    font-family: $font;
    font-size: 24px;
    font-weight: normal;
    display: block;
    color: #000000;
    border-bottom: 3px solid #eae8e4;
    padding-top: 20px;
    padding-bottom: 30px;
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 10px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 10px;

    li.current {

      > a {
        color: #000000;
      }

      &::before {
        width: 16px;
        height: 16px;
        background-position: 16px 0;
        background-size: cover;
      }
    }

    li {
      position: relative;
      margin: 0 0 25px 23px;
      font-size: 20px;

      &::before {
        content: "";
        margin-right: 7px;
        margin-left: -23px;
        display: inline-block;
        vertical-align: top;
        width: 16px;
        height: 16px;
        background: url('assets/images/cube_icon.png');
        background-size: cover;
        float: left;
      }

      &:hover::before {
        width: 16px;
        height: 16px;
        background-position: 16px 0;
        background-size: cover;
      }
    }
  }

  a {
    font-family: $title;
    font-weight: normal;
    text-decoration: none;
    color: $color_6;
    line-height: 19px;
    font-size: 20px;
    display: block;

    &:hover {
      color: $color_2;
    }
  }
}

#datepicker {

  .ui-datepicker-header {
    background: #0079af;
    color: #fff;
    text-align: center;
    padding: 28px 0;
    display: flex;
    justify-content: space-between;
  }

  .ui-datepicker-prev {
    order: -1;
    transform: rotate(180deg) translate(0px, -5px) !important;
  }

  .ui-datepicker-next {
    order: 1;
  }

  .ui-datepicker-prev,
  .ui-datepicker-next {
    background: url('assets/images/arrow-left.png') 100% no-repeat;
    width: 10px;
    height: 16px;
    display: block;
    transform: translate(0px, 5px);
    margin: 0 33px;
    cursor: pointer;

    span {
      display: none;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    thead tr {
      background: url("assets/images/calendar-tile.svg") #fff 100% no-repeat;
      z-index: 0;
    }

    tbody tr:first-child td {
      border-top: none;
    }

    th {
      width: 56px;
      height: 60px;

      span {
        color: #0079af;
        font-size: 17px;
        padding: 15px 16px;
        display: block;
      }
    }

    tr {
      position: relative;
      display: inline-block;
    }

    td {
      position: relative;
      text-align: center;
      display: inline-block;
      width: 55px;
      height: 60px;

      a, span {
        background: #f7f9f9;
        padding: 15px 10px;
        width: 38px;
        display: block;
        border: 2px solid #f6f5f3;
      }
    }

    a {
      color: #6c7174;
      text-decoration: none;
      font-size: 19px;
      font-weight: bold;
    }

    .ui-datepicker-today a {
      background: #0079af;
      color: #FFFFFF !important;
    }

    .ui-datepicker-other-month span {
      color: #6c7174;
      font-weight: bold;
      opacity: .3;
    }

    tbody tr td:last-child a {
      color: #eb556e;
    }
  }
}

.modal.open {
  display: block;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;

  .modal-content-wrap {
    display: flex;
    margin: auto;
    height: 100%;
    width: 100%;
  }

  .modal-close {
    font-family: $font;
    font-size: 21px;
    font-weight: normal;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .modal-back {
    background-color: #000;
    opacity: .5;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .modal-content {
    margin: auto;
    width: 900px;
    background: #fff;
    border-radius: 6px;
    z-index: 1;
    overflow: hidden;
  }

  .modal-header {
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
  }

  h4 {
    font-family: $font;
    margin: 0;
    font-weight: normal;
    font-size: 18px;
  }
}

@media (max-width: 1230px) {

  .wrap {
    max-width: 1170px;
  }

}

@media (max-width: 1200px) {

  .wrap {
    max-width: 970px;
  }

}

@media only screen and (max-width: 1080px) {

  #yoast-ab-icon {
    display: none;
  }

}

@media (max-width: 992px) {

  .single-inner-page .featured-image img {
    max-width: 100%;
    min-width: unset;
  }

  .page-header {
    padding: 0 15px;

    h1 {
      line-height: 1;
      font-size: 40px;
    }
  }

  .banner-holder img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    min-height: 14px;
    object-position: center;
  }

  .wrap {
    max-width: 750px;
  }

  .wrap.inner {
    padding: 0 15px;
    width: auto;
  }

  .row {
    flex-wrap: wrap;
  }

  .column-1,
  .column-2 {
    width: 100%;
    max-width: unset;
  }

}