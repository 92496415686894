
html[lang="de-DE"] {
  .header .main-nav li {
    margin-right: 30px;
  }
}

.header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 10000;
  background: #ffffff;
  padding: 0;
  transition: .25s;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  .menu {
    margin: auto 0;
  }

  .top-nav {
    background: url('assets/images/bg_menu.png');

    .socials {
      display: flex;

      a {
        margin: auto 7px;
        display: block;
        padding: 0;

        &:hover {
          border-radius: 50%;
        }
      }
    }

    .donate {
      margin-right: 0;
      margin-left: auto;
      padding: 15px 45px;
      background: #d70000;
      position: relative;
      text-align: center;
      height: 27px;
      display: block;

      span {
        font-size: 16px;
        color: #fff;
      }

      i.heart {
        position: absolute;
        left: 19px;
        transform: translate(0px, 5px);
      }

      i.btn-arrow {
        height: 15px;
        width: 8px;
        background-size: contain;
        margin: auto 0;
        transform: translate(0px, 5px);
        position: absolute;
        right: 18px;
      }
    }

    .left {
      display: flex;
    }

    .wrap {
      justify-content: space-between;
      width: 100%;
    }

    .menu {
      height: 56px;
      display: block;
    }

    li {
      line-height: 34px;
      margin-left: -6px;
      display: inline-block;
    }

    a {
      display: block;
      color: $color_1;
      padding: 10px 20px;
      font-family: $font;
      font-weight: bold;
      text-decoration: none;
      letter-spacing: normal;
      font-size: 18px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: #000000;
      }
    }
  }

  .main-nav {
    padding: 10px 0;
    width: 100%;
    flex-wrap: unset;

    .navigation {
      display: flex;
    }

    .menu {
      margin: auto 0 20px 0;
    }

    li {
      margin-right: 48px;
    }

    a {
      color: $color_1;
      font-size: 26px;
      text-decoration: none;
      letter-spacing: normal;
      font-family: $title;
      font-weight: normal;

      &:hover {
        color: $color_2;
      }
    }

  }

  .contacts {
    max-width: 190px;
    width: 100%;
    text-align: right;
    position: relative;
    margin: 15px 0 20px auto;

    a.phone {
      font-family: $font;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 2px;
      color: #000;
      margin: 0;
      display: block;
      width: 100%;
      font-weight: bold;

      span {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        letter-spacing: normal;
        vertical-align: super;
      }
    }

    a.email {
      font-family: $font;
      font-size: 14px;
      line-height: 24px;
      font-weight: bold;
      margin: 0;
      color: #000;
      display: block;
      width: 100%;

      span {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        font-weight: inherit;
        letter-spacing: normal;
        vertical-align: super;
      }
    }

  }
}

.banner-holder {
  position: relative;

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0;
  }

  a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.logo {
  max-height: 100px;
  max-width: 133px;
  margin: auto 0;
  margin-right: 38px;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.header .lang-select {
  position: relative;
  width: 100px;
  z-index: 1000;

  ul {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    &::after {
      content: "";
      width: 9px;
      height: 7px;
      background: url('assets/images/arrow_icon.png') no-repeat;
      background-size: contain;
      right: 25px;
      top: 25px;
      position: absolute;
    }

    &:hover li {
      display: flex !important;
    }
  }

  li.current-lang {
    order: -1;
    position: relative;
    list-style: none;
    display: flex !important;
    color: #FFFFFF;
    border-bottom: 1px solid transparent;
  }

  li {
    position: relative;
    display: none !important;
    margin: 0;
    color: #ffffff;
    background: #337ab7;
    width: 100px;
    height: 100%;
    max-height: 56px;
    border-bottom: 1px solid gray;

    a {
      display: block;
      color: #ffffff !important;
      text-decoration: none;
      font: 12px arial, verdana, sans-serif;
      padding: 22px 56px 22px 30px;
      margin: 0;
    }
  }
}

.menu-toggle {
  display: none;
  margin: auto 0;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 9px 10px;

  span:first-child {
    margin-top: 0;
  }

  span {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: #888;
    margin-top: 4px;
  }
}

.mobile-header .mobile-menu {
  display: block !important;
}

.header .mobile-menu {
  padding: 0 20px;

  ul.menu-nav {
    display: block;
    overflow: auto;
    max-height: calc(100vh - 80px);

    li {
      display: block;

      a {
        padding: 5px 11px 5px 11px;
      }
    }
  }

  .contacts {
    display: flex;
    flex-wrap: wrap;
    max-width: unset;
    justify-content: center;
    text-align: center;
    margin: 0;

    a {
      width: auto;
      margin: 0;
    }

    .donate {
      margin: 5px !important;
    }
  }

  .lang-select {
    width: auto;

    ul {
      position: relative;

      &::after {
        content: none !important;
      }

      li.current-lang {
        background: #d2421f;
      }

      li {
        display: inline-block !important;
        margin: 5px;
        width: calc(33.3% - 10px);

        a {
          padding: 20px;
        }
      }
    }
  }

}

.mobile-logo {
  display: none;
  max-height: 100px;
  max-width: 100px;
  margin: 0;
  order: -1;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    mix-blend-mode: darken;
  }
}

@media only screen and (max-width: 1199px) {

  .header .top-nav a {
    padding: 11px 11px 0 11px;
    font-size: 16px;
    letter-spacing: normal;
  }

  .header .main-nav .menu {
    width: 330px;
    margin-top: 0;
  }

  .header .main-nav a {
    font-size: 19px;
  }

  .header .top-nav {

    .socials, .left {
      order: -1;
    }

  }

}

@media only screen and (max-width: 767px) {

  .header .main-nav {
    display: none;
  }

  .header .top-nav {

    .menu, .left {
      display: none;
    }

  }

  .menu-toggle {
    display: block;
  }

  .mobile-logo {
    display: block;
  }
}

@media only screen and (max-width: 400px) {

  .header .mobile-menu .lang-select {
    width: 100%;
  }
}