
@keyframes gdpr-slide-up {
  0%, 70% {
    -webkit-transform: translateY(200px);
    -ms-transform: translateY(200px);
    transform: translateY(200px);
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes gdpr-slide-down {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(200px);
    -ms-transform: translateY(200px);
    transform: translateY(200px);
  }
}

#gdpr-consent {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 10px;
  width: 100%;
  max-width: 800px;
  margin: auto;
  z-index: 9001;

  .cc-invisible {
    transition: 1s;
    transform: translateY(200px);
  }

  .cc-window {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    font-family: $font;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0.015px 5px 0 rgba(0,0,0,0.24);
    animation-duration: 1.45s;
    animation-name: gdpr-slide-up;
  }

  .cc-compliance {
    margin: auto;
  }

  .cc-dismiss {
    position: relative;
    padding: 8px 18px;
    display: block;
    cursor: pointer;
    font-size: 15px;
    background: $color_3;
    background-position: center;
    margin: 0;
    color: #fff !important;
  }

  .cc-message {
    max-width: 85%;
    font-size: 14px;
    color: #222;
    text-align: left;
    font-family: $font;
    font-weight: normal;
    margin: 0;

    a {
      color: $color_3;

      &:hover {
        text-decoration: underline;
      }
    }

    p, a {
      font-size: 14px;
      font-family: $font;
      margin: 0;
      font-weight: normal;
    }
  }

}

@media only screen and (max-width: 990px) {

  .cc-message, .cc-compliance {
    max-width: 100%;
  }

}