@import "fonts";
@import "icons";

.wp-block {
  max-width: 800px;
}

.justify-text {
  text-align: justify;
  display: inline-block;
}

.wp-block-gallery {

  li {
    margin: 13px 13px 13px 0 !important;
    width: 23% !important;
    max-width: 204px;
    flex-grow: unset;
    display: block;

    figure {
      height: 112px !important;

      a {
        height: inherit;
      }

      img {
        width: 100% !important;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }

}

.slider-block {

  .slider {
    overflow: hidden;
  }

  .content {
    max-width: 1000px;
    padding-top: 100px;
  }

  .background {
    position: relative;

    img {
      max-width: 100%;
      display: block;
      width: 100%;
    }
  }

  .wrap {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  h2 {
    margin: 0;
    font-size: 90px;
    line-height: 1;
  }

  h3 {
    font-family: $font;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .swiper-wrapper {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .swiper-slide {
    color: #fff;
  }
}

.btn.tickets {
  color: #ffffff;
  background: $color_2 !important;
  box-shadow: none !important;
  display: inline-block;
  width: 207px;
  padding: 15px;
  padding-left: 52px;
  margin: 0;
  text-align: left;
  font-weight: bold;
  position: relative;
  border: none;

  &:hover {
    background: $color_7;
    color: #ffffff;
  }
}

.gallery-block {

  .single-gallery-entry {
    position: relative;
    background: #ffffff;
    padding: 10px 10px 18px 10px;
    margin: 14px;
    margin-left: -5px;
    display: inline-block;
    vertical-align: top;
    box-shadow: 1px 2px 5px #e8e8e8;

    a.link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    h4 {
      text-align: left;
      font-family: $title;
      color: #000000;
      line-height: 24px;
      font-size: 21px;
      margin: 19px 0 0 0;
      font-weight: normal;
    }

    .image {
      width: 347px;
      height: 193px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.archive-ticket {

  .buttons {
    text-align: right;
    padding-bottom: 38px;
  }

  .btn {
    box-shadow: inset 0 0 0 100px rgba(165, 160, 147, .4);
    background: url('assets/images/bg_menu.png');
    width: 244px;
    margin: 0;
    z-index: 100;
    display: inline-block;
    line-height: normal;
    position: relative;
    padding: 15px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    margin-right: 20px;

    &:hover {
      background: #d2421f;
      box-shadow:  2px 2px 21px rgba(165, 160, 147, .45);
      color: #fff;
    }
  }

  .tickets-block {

    .image {
      margin-right: 30px !important;
      width: 180px !important;
      height: 127px !important;
      min-width: 180px !important;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        display: block;
      }

      .meta {
        margin: 0;
      }

      span {
        font-size: 18px !important;
        line-height: 26px !important;
        font-family: $font !important;
        margin: 0 !important;
        display: block !important;
        text-transform: unset !important;
      }
    }

    .content {
      max-width: unset !important;
    }

    .excerpt p {
      margin: 0;
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 26px;
    }
  }

}

.events-block {

  .post-list {
    padding: 0 !important;
  }

  .block-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 70px 0;

    h2 {
      max-width: 520px;
    }
  }

  .single-post {

    .content {
      padding-left: 45px !important;

      .meta {
        margin-left: -45px;
        margin-top: 0;
      }

      .btn {
        margin-left: 45px;
      }
    }
  }

  .single-post:first-child {

    .content {
      padding-left: 50px !important;

      .meta {
        margin-left: -92px !important;
      }

      .btn {
        margin-left: 50px;
      }
    }
  }

  h2 {
    font-family: $title;
    color: $color_6;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  .single-post .image {
    background: #ccc;
  }

  .filter {
    display: flex;
    flex-wrap: wrap;

    span {
      margin: 0 13px auto 0;
      font-size: 16px;
      color: #888888;
      height: 59px;
      position: relative;
      line-height: 3;
    }
  }

  .categories-wrap {
    position: relative;
    width: 400px;
    max-width: calc(100vw - 30px);
    z-index: 100;

    &::after {
      content: "";
      background: url('assets/images/arrow-down.svg') 100% no-repeat;
      width: 15px;
      height: 8px;
      display: block;
      position: absolute;
      right: 10px;
      top: 20px;
    }
  }

  .categories {
    padding: 0;
    list-style: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    background: #fff;
    border: 1px solid #888888;
    max-height: 327px;
    overflow: auto;

    &:hover li {
      display: block;
    }

    li.current {
      display: block;
      order: -1;
      border: none;
    }

    ul {
      padding-left: 20px;
      overflow: hidden;

      li {
        border: none;
      }
    }

    li.parent-0 {
      padding-left: 0;
    }

    li {
      display: none;
      width: 400px;
      max-width: calc(100vw - 30px);
      padding-left: 20px;
    }

    a {
      font-size: 16px;
      color: #888888;
      display: block;
      text-decoration: none;
      padding: 10px;
    }
  }

}

.news-block,
.events-block {
  width: 100%;

  h1 {
    font-family: $title;
    color: $color_6;
    text-transform: uppercase;
    font-weight: normal;
    margin: 70px 0;
  }

  .post-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .single-post:first-child {
      max-width: 842px;

      .image {
        height: 362px;
      }

      .content {
        margin-top: -154px;
        z-index: 100;
        position: relative;
        margin-left: 42px;

        .h2, h2 {
          font-size: 1.8em;
          line-height: 30px;
        }
      }

      .meta {
        margin-left: -62px;
      }
    }
  }

  .long-title {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  .post-list-normal {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    .single-post {
      margin-left: 18px;
      margin-right: 18px;
    }

    .single-post:first-child .content {
      padding-left: 45px !important;

      .meta {
        margin-left: -45px !important;
      }
    }
  }

  .single-post {
    max-width: 404px;
    margin-bottom: 40px;
    position: relative;
    width: 100%;

    .image {
      position: relative;
      display: block;
      overflow: hidden;
      width: 100%;
      height: 208px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      padding: 0 20px 25px 21px;
      position: relative;
      background: #fff;
      height: 297px;

      .h2, h2 {
        font-family: $title;
        font-size: 24px;
        line-height: 30px;
        color: $color_6;
        margin-top: 20px;
        margin-bottom: 0;
        font-weight: normal;
        display: block;
        text-decoration: none;

        &:hover {
          color: $color_2;
        }
      }

      .excerpt p {
        margin: 18px 0 12px;
      }
    }

    .meta {
      font-family: $title;
      font-weight: bold;
      background: $color_5;
      display: inline-block;
      color: white;
      margin-top: -12px;
      margin-left: -21px;
      padding: 10px 25px 18px 25px;
    }

    .btn {
      box-shadow: inset 0 0 0 100px rgba(165, 160, 147, .4);
      background: url('assets/images/bg_menu.png');
      width: 210px;
      margin: 0;
      z-index: 100;
      display: inline-block;
      line-height: normal;
      position: absolute;
      bottom: -25px;
      left: 0;
      padding: 13px 15px;

      &:hover {
        box-shadow: inset 0 0 0 100px rgba(0, 0, 0, .2), 2px 2px 21px rgba(165, 160, 147, .45);
      }
    }
  }

  .post-list-footer {
    text-align: center;
    padding: 30px 0 90px 0;

    a {
      display: inline-block;
      font-family: $title;
      font-size: 20px;
      color: $color_6;
      text-decoration: none;

      &:hover {
        color: $color_2;
      }
    }

  }
}

@media only screen and (max-width: 1229px) {

  .news-block .single-post {
    width: 100% !important;
    max-width: unset !important;
  }

  .news-block .single-post .image {
    height: 59% !important;
  }

}

@media only screen and (max-width: 991px) {

  .slider-block .wrap {
    max-width: unset;
    background: #33507c;
    position: relative;

    .content {
      margin: auto;
      padding: 30px 15px;
      max-width: 750px;
    }
  }

  .news-block .single-post {

    .content {
      height: auto;
      margin-left: 0 !important;
      margin-top: unset !important;
    }

    .meta {
      margin-left: -21px !important;
    }

    .btn {
      left: unset;
      right: 0;
    }
  }

}

@media only screen and (max-width: 780px) {

  .archive-page .single-post {
    flex-wrap: wrap;

    .meta {
      margin: 0 !important;
      order: -1;
    }

    .image {
      display: none;
    }

    .content .h2 {
      font-size: 20px;
      margin-bottom: 10px;
      line-height: 20px;
    }
  }

  .gallery-block {
    text-align: center;

    .single-gallery-entry {
      max-width: calc(100% - 20px);
      margin: 0;
      width: auto;

      .image {
        max-width: 100%;
      }
    }
  }

  .news-block .single-post .image {
    height: auto !important;
  }

  .news-block h1, .events-block h1 {
    padding: 0 15px;
    margin: 30px 0;
  }

  .slider-block {

    h2 {
      font-size: 40px;
      text-align: center;
    }

    h3 {
      text-align: center;
    }
  }

  .news-block .post-list,
  .events-block .post-list {
    padding: 0 15px;
  }

  .events-block .single-post:first-child {

    .content {
      padding-left: 20px !important;
      margin: 0 !important;

      .meta {
        margin-left: 0 !important;
      }

      .btn {
        margin-left: 0;
      }

      .h2 {
        font-size: 24px !important;
        line-height: 30px !important;
      }
    }
  }

  .events-block .single-post {

    .content {
      padding-left: 20px !important;

      .meta {
        margin-left: 0 !important;
      }

      .btn {
        margin-left: 0;
      }

      .h2 {
        font-size: 24px !important;
        line-height: 30px !important;
      }
    }
  }

}