
i.email-icon {
  background: url('assets/images/email_icon.png');
  background-position-y: 9px;
  width: 27px;
  height: 27px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
}

i.phone-icon {
  background: url('assets/images/phone_icon.png');
  width: 24px;
  height: 24px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;
}

i.btn-arrow {
  width: 15px;
  float: right;
  height: 48px;
  margin-top: -12px;
  background: url('assets/images/btn_arrow.png') center no-repeat;
  background-size: auto 30px;
}

i.square-icon {
  background: url('assets/images/cubes_icon.png');
  width: 43px;
  height: 15px;
  display: inline-block;
  background-size: auto 16px;
}

i.location-icon {
  background: url('assets/images/location_icon.png');
  width: 15px;
  height: 24px;
  display: inline-block;
  background-size: auto 24px;
  position: absolute;
  left: 20px;
  top: 16px;
}

i.ticket-icon {
  background: url('assets/images/ticket_icon.png');
  width: 23px;
  height: 24px;
  display: inline-block;
  background-size: auto 24px;
  position: absolute;
  left: 16px;
  top: 16px;
}

i.fb-icon {
  background: url(assets/images/bg_foot_fb.png) 3px 0;
  background-size: auto 50px;
  background-repeat: no-repeat;
  height: 50px;
  width: 53px;
  display: inline-block;
  vertical-align: bottom;
}

i.insta-icon {
  background: url(assets/images/bg_foot_insta.png) 3px 0;
  background-size: auto 50px;
  background-repeat: no-repeat;
  height: 50px;
  width: 53px;
  display: inline-block;
  vertical-align: bottom;
}

i.fb {
  background: url(assets/images/fb.png) 0 0;
  background-size: auto 40px;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: inline-block;
  vertical-align: bottom;
}

i.insta {
  background: url(assets/images/insta.png) 0 0;
  background-size: auto 40px;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: inline-block;
  vertical-align: bottom;
}

i.patreon {
  background: url(assets/images/patreon.png) 0 0;
  background-size: auto 40px;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  display: inline-block;
  vertical-align: bottom;
}


i.heart {
  background: url(assets/images/heart.png) 0 0;
  background-size: auto 17px;
  background-repeat: no-repeat;
  height: 19px;
  width: 19px;
  display: inline-block;
  vertical-align: bottom;
}