
footer {
  background: url('assets/images/bg_foot.png');
  background-size: auto 300px;
  padding: 60px 0 35px 0;
  color: #999999;

  .wrap {
    width: 100%;
  }

  .top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    border-bottom: 2px solid #fff;
    padding-bottom: 50px;
    margin-bottom: 30px;
  }

  .address {
    color: #000000;
    display: inline-block;
    width: 207px;
    background: url('assets/images/bg_menu.png');
    box-shadow: inset 0 0 0 100px rgba(165, 160, 147, .4),
    2px 2px 21px rgba(165, 160, 147, .45);
    padding: 15px;
    padding-left: 52px;
    margin: 0;
    margin-right: 20px;
    text-align: left;
    font-weight: bold;
    position: relative;
    border: none;

    &:hover {
      background: $color_2;
      color: #ffffff;
      box-shadow: 2px 2px 21px rgba(165, 160, 147, .45);
    }
  }

  .tickets {
    color: #ffffff;
    background: $color_2;
    box-shadow: none;
    display: inline-block;
    width: 207px;
    padding: 15px;
    padding-left: 52px;
    margin: 0;
    margin-right: 20px;
    text-align: left;
    font-weight: bold;
    position: relative;
    border: none;

    &:hover {
      background: $color_7;
    }
  }

  .copyright {
    padding-top: 21px;
    font-size: 12px;
    line-height: 15px;
    max-width: 792px;
    margin: 0;
  }

  .socials {
    display: flex;
    flex-wrap: wrap;
  }

  .fb {
    margin: 0;
    display: block;
    line-height: 50px;
    text-decoration: none;
    color: $color_6;
    margin-right: 60px;

    i {
      margin-right: 10px;
    }

    &:hover {
      color: $color_2;

      i {
        background: url('assets/images/bg_foot_fb.png') -49px 0px;
        background-size: auto 50px;
        background-repeat: no-repeat;
      }
    }
  }

  .insta {
    margin: 0;
    display: block;
    line-height: 50px;
    text-decoration: none;
    color: $color_6;

    i {
      margin-right: 10px;
    }

    &:hover {
      color: $color_2;

      i {
        background: url('assets/images/bg_foot_insta.png') -49px 0px;
        background-size: auto 50px;
        background-repeat: no-repeat;
      }
    }
  }
}

@media only screen and (max-width: 700px) {

  footer {
    padding: 20px 0 20px 0;

    .buttons {
      text-align: center;
    }

    .address {
      margin: 0 0 10px;
    }

    .socials {
      justify-content: center;

      a {
        margin: 10px;
      }
    }

    .copyright {
      padding-top: 0;
    }

    .bottom {
      padding: 0 15px;
    }
  }

}